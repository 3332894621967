<template>
  <div class="resize">
    <div class="l-col">
      <base-select
        v-model="image"
        select-label=""
        :hide-selected="true"
        :searchable="false"
        :options="images"
        placeholder="Выберите образ Live CD"
        size="medium"
        :custom-label="nameImage"
        field-label="Образы Live CD:"
        :hint="'Вы можете выбрать предустановленный образ Live CD, либо загруженые Вами ранее'"
        :required="true"
        :preselect-first="true"
        track-by="name"
        :allow-empty="false"
        class="new-disk__services"
        @input="onChange"
      >
      </base-select>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
export default {
  name: 'RescueServer',
  components: { BaseSelect },
  mixins: [setFocus],
  props: {
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      image: '',
    };
  },
  computed: {
    images() {
      return this.$store.state.moduleStack.images.filter(x => x.tags.includes('rescue'));
    },
  },
  mounted() {
    this.newNameServer = this.name;
  },
  methods: {
    nameImage({ name, size }) {
      return `${name} (${(size / 1024 ** 3).toPrecision(2)}ГБ)`;
    },
    onChange() {
      this.$emit(
        'change',
        {
          rescue: {
            rescue_image_ref: this.image.id,
          },
        }

        // this.newNameServer.length > 63 ? this.newNameServer.substr(0, 63) : this.newNameServer
      );
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "server": {
      "name": "Новое название сервера",
      "description" : "Текстовое описание"
    },
    "sure": {
      "confirm": "Хорошо"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;
}
</style>
