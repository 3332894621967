import ChangePassword from '@/layouts/Stack/components/ChangePassword.vue';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins';
import Vue from 'vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorModal, storeMixin, modals],
  data() {
    return {
      data: {},
      isProcessing: false,
    };
  },
  methods: {
    changePassword: function (instance) {
      const that = this;
      return new Promise(() => {
        const selfName = 'changePassword';
        this.$modals.open({
          name: selfName,
          size: 'small',
          component: ChangePassword,
          closeOnBackdrop: false,
          props: {
            name: instance.name,
          },
          on: {
            change: data => {
              instance = data;
              // console.log(data);
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: () => {
                  Vue.set(this.modal, 'footer', {
                    confirm: {
                      props: { loading: true },
                    },
                  });
                  const params = {
                    changePassword: {
                      adminPass: instance,
                    },
                  };
                  this.sendServerAction(JSON.stringify(params))
                    .then(data => {
                      // console.log(data);
                      if (data === 202) {
                        that.$modals.close();
                        this.showResModal('Пароль успешно изменён.');
                      }
                    })
                    .catch(e => {
                      that.$modals.close();
                      if (e.response.status === 501) {
                        this.showError('Не установлен guestAgent');
                      } else this.showError(e);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    sendServerAction(params) {
      return this.$store.dispatch('moduleStack/setServerAction', {
        id: this.tariff.id,
        params,
      });
    },
    // makeModal(props = {}) {
    //   this.$modals.open({
    //     name: 'AddModal',
    //     size: 'small',
    //     onOpen: inst => (this.modal = inst),
    //     onClose: () => (this.modal = null),
    //     onDismiss: () => (this.modal = null),
    //     ...props,
    //   });
    // },
    // showResModal(res, props = {}) {
    //   if (!this.modal) this.makeModal(props);
    //   Vue.set(this.modal, 'component', null);
    //   Vue.set(this.modal, 'closable', true);
    //   Vue.set(this.modal, 'text', res);
    //   Vue.set(this.modal, 'footer', {
    //     confirm: {
    //       on: {
    //         click: () => {
    //           this.$modals.close();
    //         },
    //       },
    //     },
    //   });
    // },
  },
};
